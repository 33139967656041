// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form {
    min-width: calc(50% - 24px);
}

@media (max-width: 939px){
    form {
        min-width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/component/HeroContent/AuthCodeSearchInput.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":["form {\n    min-width: calc(50% - 24px);\n}\n\n@media (max-width: 939px){\n    form {\n        min-width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
