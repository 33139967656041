import React from 'react'
import PageTitle from './PageTitle.jsx'
import AuthCodeSearchInput from './AuthCodeSearchInput.jsx'

const hero = ({ setAuthCode, setFetchError, setAuthNotFound, setConfirmation, setReference, setInvalidReturnAgreement }) => {
  return (
    <div className='hw-hero hw-hero--small'>
      <div className='hw-hero__image' style={{ backgroundImage: "url('images/posten-bring-hero.jpg')" }} />
      <div className='hw-hero__overlay hw-background-color-opaque-black'>
        <div className='hw-container' style={{ height: '100%' }}>
          <div className='hw-hero__content hw-hero__content--middle hw-hero__content--left'>
            <PageTitle />
            <AuthCodeSearchInput
              setAuthCode={setAuthCode}
              setFetchError={setFetchError}
              setAuthNotFound={setAuthNotFound}
              setConfirmation={setConfirmation}
              setReference={setReference}
              setInvalidReturnAgreement={setInvalidReturnAgreement}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default hero
