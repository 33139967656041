import React, { useContext } from 'react'
import { getCountryName } from '../GetCountryName/GetCountryName'
import ContentContext from '../../Context/ContentContext.js'
import { useFormContext } from 'react-hook-form'

const countrySelect = ({ formFor, onChange }) => {
  const countryCodes = new Set([])
  const context = useContext(ContentContext)
  const { register, trigger, clearErrors, setValue } = useFormContext()

  context.receiverAddresses.map(address => {
    countryCodes.add(address.appliesToCountryCode)
    if (formFor === 'recipient' && !countryCodes.has(address.countryCode)) {
      countryCodes.add(address.countryCode)
    }
  })

  const countryOptions = Array.from(countryCodes).map(code => {
    return <option key={code} value={code}>{context.t(getCountryName(code))}</option>
  })

  const select =
    <select
      className='hw-dropdown--native'
      data-hw-dropdown='countries'
      name={formFor + '.countryCode'}
      id='countries'
      data-testid={`${formFor}CountrySelect`}
      {...register(formFor + '.countryCode', {
        required: true,
        onChange: () => {
          clearErrors(formFor + '.postalCode')
          setValue(formFor + '.postalCity', '')
          trigger(formFor + '.postalCode')
          onChange()
        }
      })}
      disabled={countryCodes.size <= 1}
    >
      {countryOptions}
    </select>

  return (
    <>
      <label className='hw-label'>
        {context.t('formLabels.country')}
        {select}
      </label>
    </>
  )
}

export default countrySelect
