const en = {
  pageTitle: {
    title: 'Order return shipment'
  },
  authCodeSearch: {
    inputAuthCodeLabel: 'Enter return code',
    inputAuthCodePlaceholder: 'Ex. 35424374',
    noresult: 'Return code not found',
    checkifcorrectcode: 'Check if you have entered the correct code',
    authcodeisnotshippingcode: 'You get the return code from the sender of the parcel you received. Note that the shipment number on this parcel is not the return code'
  },
  formLabels: {
    name: 'Name',
    address: 'Address',
    addressline2: 'Address line 2',
    phoneNumber: 'Mobile number',
    email: 'E-mail - if you want to receive label/QR-code by e-mail',
    emailWithoutQrCode: 'E-mail - if you want to receive label by e-mail',
    postalcode: 'Postal code',
    city: 'City',
    country: 'Country',
    numberOfPackages: 'Packages',
    reference: 'Reference',
    firstname: 'First name',
    lastname: 'Last name',
    weight: 'How much does your parcel weight?',
    returningwhat: 'What are you returning?'
  },
  weightAndDimensions: {
    weight: 'Weight (kg)',
    length: 'Length (cm)',
    width: 'Width (cm)',
    height: 'Height (cm)'
  },
  errormsg: {
    invalidpostalcode: 'Invalid postal code',
    cannotbeempty: 'Cannot be empty',
    maxpackages: 'Max 50',
    minonepackage: 'Min 1',
    errorhappened: 'We cannot do searches right now',
    trylaterrefresh: 'Try refreshing the page, or try again later',
    fieldlength: 'Must be between 1 and 35 characters',
    exactlyfourdigits: 'Must be exactly 4 digits',
    exactlyfivedigits: 'Must be exactly 5 digits',
    emailformat: 'Invalid e-mail address',
    weight: 'You have to choose a weight. Must be ',
    missingdimensionsfield: 'Weight and dimensions are required.',
    dimensions1: 'Longest side max ',
    dimensions2: 'Longest side + circumference max ',
    validationphoneNumberNO: 'The number must be a valid Norwegian phone number (+47 12345678) ',
    validationphoneNumberSE: 'The number must be a valid Swedish phone number (+46 700000000) ',
    validationphoneNumberDK: 'The number must be a valid Danish phone number (+45 12345678) ',
    validationphoneNumberFI: 'The number must be a valid Finnish phone number (+358201234567) '

  },
  confirmation: {
    title: 'Order confirmation',
    from: 'From',
    to: 'To',
    printlabel: 'Print return label',
    printlabelregular: 'Print return label on a regular printer (A4)',
    printlabelspecial: 'Print return label on a dedicated label printer',
    trackingnumber: 'Tracking number',
    orderpickup: {
      buttonLabel: 'Order Pickup from mailbox',
      contentheader: 'Pickup from mailbox',
      para1: 'You can easily order pickup of the return directly from your own mailbox. If pickup is included for this return, you only confirm the pickup date. Alternatively, the additional service costs NOK 15. For us to know who you are and ensure we have the correct address, you must log in before ordering.'
    },
    qrcodes: {
      title: 'QR-code',
      intro: 'You can print the return label at all of Posten’s pickup points by scanning the QR-code below.',
      otherwise: 'You may also print the label yourself in advance, if you want.',
      here: 'Here is your QR-code (one per item)',
      once: 'The QR-code can only be used once.'
    }
  },
  sender: 'Sender',
  packages: 'Packages',
  recipient: 'Recipient',
  gotohomepagelink: 'Go to homepage',
  btnlabels: {
    search: 'Search',
    editreceiver: 'Edit recipient',
    generatelabel: 'Create return label',
    creatinglabel: 'Creating return label...',
    usedefault: 'Use default',
    searching: 'Searching..',
    viewreturnlabel: 'View return label'
  },
  countries: {
    norway: 'Norway',
    denmark: 'Denmark',
    sweden: 'Sweden',
    finland: 'Finland'
  },
  smallpacketmaxsize: 'Maximum size per parcel: Length 60 cm and length + width + height must not be above 90 cm',
  saturdayDelivery: {
    eligible: 'The return has expected delivery over the weekend, but can be sped up to the coming Saturday.',
    eligibleUnconfirmed: 'Due to technical issues, we cannot verify if Saturday delivery service may speed up this return.',
    checkboxText: 'Order Saturday delivery'
  },
  newconfirmation: {
    back: 'Back',
    title: 'Your return shipment has been ordered.',
    info: 'The QR code can be used to print the label at one of Posten\'s pickup points, or you can print it yourself.',
    qrCodes: {
      title: 'Your QR code',
      titleMultiple: 'Your QR codes (one per package)',
      package: 'package',
      of: 'of',
      sentTo: 'The QR code has also been sent to',
      instructions: {
        title: 'Here is what you do:',
        stepOne: 'Visit your nearest Posten\'s pickup point.',
        stepTwo: 'Scan the QR code. The label will be automatically printed.',
        stepThree: 'Attach the label to the center of the package with the barcode clearly visible.',
        stepFour: 'Drop off the package.',
        pickupPoints: 'Here you can find our pickup points.',
      },
    },
    printer: {
      title: 'Do you have a printer available?',
      info: 'If you have the opportunity to use a printer, you can also',
      printLabel: 'print the label yourself.',
    },
    pickup: {
      title: 'Send the return from your mailbox?',
      info: 'Once you have printed and prepared the return label, you can ',
      orderPickup: 'order collection from your letterbox.',
    },
    tracking: {
      title: 'Track your return',
      titleMultiple: 'Track your returns',
      info: 'When you have delivered your return shipment to one of our drop-off locations, you can track the parcel with your package number ',
      infoMultiple: 'When you have delivered your return shipments to one of our drop-off locations, you can track the parcels with your shipment number ',
      at: ' at posten.no.'

    },
    newB2CReturnWith1073: {
      pickup: {
        title: 'Our pickup points',
        info: 'Find your ',
        infoLink: 'closest Posten pickup point.',
      },
      sendFromMailbox: {
        title: 'Send the return from your mailbox or parcel locker?',
        printLabel: 'If you have access to a printer, you can ',
        printLabelLink: 'print the label yourself.',
        info: 'When you have the return label ready, feel free to send the parcel from your mailbox or a parcel locker using the Posten app.',
        mailbox: 'Order pickup from the mailbox',
        parcelLocker: 'Open the Posten app',
      },
    },
    nordicParcels: {
      info: 'Visit a Bring pickup point and show the QR code to get your return label.',
      instructions: {
        title: 'This is what you do:',
        stepOne: 'Visit your closest Bring pickup point.',
        stepTwo: 'Show the QR code.',
        stepThree: 'Attach the return label on the parcel.'
      },
      findPostOffice: 'Find a Bring pickup point',
      labelFree: {
        title: 'Does the pickup point need the parcel code?',
        info: 'The code is used instead of the label for some pickup points. Write the code below clearly on the parcel before handing it in.'
      },
      printer: {
        title: 'Do you have a printer at home?',
        info: 'Print the return label yourself.',
      },
    },
  },
}

export default en