import { env } from "./config/envConfig";
export function shouldRequestLabelFreePilot(customerNumber, service, senderCountryCode, senderPostalCode) {
    var isAllowedEnvironment = env.includes('localhost') || env.includes('test') || env.includes('qa');
    var isSentFromNorway = senderCountryCode === 'NO';
    // const isApplicableCustomer = ['10001508059'].includes(customerNumber)
    var applicableServices = ['9000', '9300', '9350', '9600', '9650', 'PICKUP_PARCEL_RETURN', 'PICKUP_PARCEL_RETURN_BULK', 'BUSINESS_PARCEL_RETURN', 'BUSINESS_PARCEL_RETURN_BULK'];
    var isApplicableService = applicableServices.includes(service);
    var applicablePostalCodePrefix = ['31', '32', '37', '38', '39'];
    var isApplicablePostalCode = applicablePostalCodePrefix.some(function (postalCode) { return senderPostalCode.startsWith(postalCode); });
    return isAllowedEnvironment && isSentFromNorway && isApplicableService && isApplicablePostalCode;
}
