import React, { useContext } from 'react';
import { contentContext } from '../../Context/ContentContext';
var labelFreeStyle = {
    backgroundColor: 'var(--hw-color-primary-light)',
    borderRadius: '2px',
    width: 'fit-content',
    padding: '.5rem 2.5rem',
    fontSize: '1.2rem'
};
var LabelFreePilot = function (_a) {
    var _b;
    var confirmation = _a.confirmation;
    var ctx = useContext(contentContext);
    return (React.createElement(React.Fragment, null,
        React.createElement("p", { className: 'hw-block hw-block--mt-small-4 hw-block--mb-small-4' }, "F\u00F8lg instruksene under for \u00E5 innlevere returen."),
        React.createElement("div", { className: 'hw-block hw-block--mb-large' },
            React.createElement("h3", { className: 'hw-block--mb-small-4 hw-text-medium' }, "Her er koden din"),
            React.createElement("div", null, (_b = confirmation.resp.packages) === null || _b === void 0 ? void 0 : _b.map(function (p, index) {
                return (React.createElement("div", { className: 'hw-block--mb --hw-h2-font-family', style: labelFreeStyle, key: index }, p.labelFreeCode));
            }))),
        React.createElement("div", { className: 'hw-block hw-block--mb-large' },
            React.createElement("div", { className: 'hw-wysiwyg' },
                React.createElement("h3", { className: 'hw-block--mb-small-4 hw-text-medium' }, "Slik gj\u00F8r du:"),
                React.createElement("ol", { style: { marginTop: '0' } },
                    React.createElement("li", null, "Skriv koden tydelig og lesbart p\u00E5 pakken."),
                    React.createElement("li", null,
                        "Innlever pakken p\u00E5 et av ",
                        React.createElement("a", { href: 'https://www.posten.no/kart', className: 'hw-link hw-link--inline', target: '_blank', rel: 'noopener noreferrer' }, "Postens innleveringssteder"),
                        ", eller bruk Posten-appen for \u00E5 sende fra Pakkeboks.")))),
        React.createElement("div", { className: 'hw-block hw-block--mt-large' },
            React.createElement("h3", { className: 'hw-block--mb-small-4 hw-text-medium' }, "Spor pakken i Posten appen"),
            React.createElement("p", { className: 'hw-block hw-block--mt-small-4' },
                "Du kan spore pakken i Posten appen eller p\u00E5 posten.no med sporingsnummeret: ",
                confirmation.resp.consignmentNumber,
                "."),
            React.createElement("p", { className: 'hw-block hw-block--mt-small-4' }, "Du trenger ikke kvittering, men \u00F8nsker du det likevel kan du gi beskjed til personalet og oppgi sporingsnummeret."))));
};
export default LabelFreePilot;
