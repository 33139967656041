import React, { useContext, useEffect } from 'react'
import 'isomorphic-fetch'
import ContentContext from '../../Context/ContentContext'
import api from '../../api'
import { useFormContext } from 'react-hook-form'
import { removeAllWhitespaceInString, stringHasAllNumbers } from '../../helpers/stringHelpers'

const postalCodeLocationInput = ({ formFor, onChange, setValidPostCode, senderCountryCode, recipientCountryCode }) => {
  const ctx = useContext(ContentContext)
  const { register, formState: {errors}, getValues, watch, trigger, setValue } = useFormContext()
  const postalCodeLocationGrid = {
    display: 'flex',
    flexFlow: 'row wrap'
  }
  const postalCodeInputStyle = {
    marginRight: '20px',
    width: '108px'
  }
  const cityStyle = {
    flex: '1'
  }

  const postalCodeWatch = watch(formFor + '.postalCode')
  const postalCityWatch = watch(formFor + '.postalCity')
  const countryCode = formFor === 'sender' ? senderCountryCode : recipientCountryCode

  const postalCodeIsValidForCountryCode = (possiblePostalCode, countryCode) => {

    const strippedPossiblePostalcode = removeAllWhitespaceInString(possiblePostalCode)

    let errmsg = ''
    let isValid = true;

    const norwegianAndDanishIsValid = strippedPossiblePostalcode.length === 4 && stringHasAllNumbers(strippedPossiblePostalcode)
    const swedishAndFinnishIsValid = strippedPossiblePostalcode.length === 5 && stringHasAllNumbers(strippedPossiblePostalcode)

    // TODO Error message is not showing correctly when changing country
    if (new Set(["NO", "DK"]).has(countryCode) && !norwegianAndDanishIsValid) {
      errmsg = ctx.t('errormsg.exactlyfourdigits')
      setValue(formFor + '.postalCity', ctx.t('errormsg.invalidpostalcode'))
      isValid = false;
    }
    
    if (new Set(["SE", "FI"]).has(countryCode) && !swedishAndFinnishIsValid) {
      errmsg = ctx.t('errormsg.exactlyfivedigits')
      setValue(formFor + '.postalCity', ctx.t('errormsg.invalidpostalcode'))
      isValid = false;
    }

    return {
      errorDetails: errmsg === '' && getValues(formFor + '.postalCity') !== ctx.t('errormsg.invalidpostalcode') ? true : errmsg,
      valid: isValid
    }
  }

  const callPostalCodeAPI = (value, countryCode) => {
    if (postalCodeIsValidForCountryCode(value, countryCode).valid) {

      const strippedPostalCode = removeAllWhitespaceInString(value)

      api.validatePostalCode(strippedPostalCode, countryCode)
        .then(res => {
          if (res.valid) {
            if (!!setValidPostCode) {
              setValidPostCode(strippedPostalCode)
            }
            setValue(formFor + '.postalCity', res.result)
          } else {
            setValue(formFor + '.postalCity', ctx.t('errormsg.invalidpostalcode'))
          }
          trigger(formFor + '.postalCode')
        })
    } else {
      setValue(formFor + '.postalCity', ctx.t('errormsg.invalidpostalcode'))
    }
  }

  useEffect(() => {
    if (postalCodeWatch && countryCode && !errors[formFor + '.postalCode']) {
      callPostalCodeAPI(postalCodeWatch, countryCode)
      trigger(formFor + '.postalCode')
    }
  }, [postalCodeWatch, countryCode])

  useEffect(() => {
    if (postalCityWatch && postalCityWatch !== ctx.t('errormsg.invalidpostalcode')) {
      trigger(formFor + '.postalCity')
    }
  }, [postalCityWatch])

  return (
    <div style={postalCodeLocationGrid}>
      <label className='hw-label' style={postalCodeInputStyle}>
        {ctx.t('formLabels.postalcode')}
        <input
          name={formFor + '.postalCode'}
          id='PostalCodeInput'
          data-testid={`${formFor}PostalCodeInput`}
          className={errors[formFor]?.postalCode ? 'hw-input hw-input--error' : 'hw-input'}
          type='text'
          inputMode='numeric'
          {...register(formFor + '.postalCode', {
            required: ctx.t('errormsg.cannotbeempty'),
            validate: value => postalCodeIsValidForCountryCode(value, countryCode).errorDetails,
            onChange: onChange
          })}
        />
        {errors[formFor]?.postalCode && errors[formFor]?.postalCode?.message && <span className='hw-error' data-testid={`${formFor}PostalCodeError`}> {errors[formFor]?.postalCode?.message} </span>}
      </label>
      <label className='hw-label' style={cityStyle}>
        {ctx.t('formLabels.city')}
        <input
          type='text'
          name={formFor + '.postalCity'}
          data-testid={`${formFor}CityInput`}
          className='hw-input'
          {...register(formFor + '.postalCity', {
            required: true,
            validate: value => value !== ctx.t('errormsg.invalidpostalcode'),
            onChange: onChange
          })}
          disabled
        />
      </label>

    </div>
  )
}

export default postalCodeLocationInput
