const pickupUri = 'bestill/henting/retur'
const envConfig = {
  sendPickupUrlTest: `http://sending.test.posten.no/${pickupUri}/`,
  sendPickupUrlQA: `https://sending.qa.posten.no/${pickupUri}/`,
  sendPickupUrlPROD: `https://sending.posten.no/${pickupUri}/`
}

export const env = window.location.href || ''

export const url = (env.includes('test')) ? envConfig.sendPickupUrlTest : (env.includes('qa')) ? envConfig.sendPickupUrlQA : envConfig.sendPickupUrlPROD
