const sv = {
  pageTitle: {
    title: 'Beställ ny retur'
  },
  authCodeSearch: {
    inputAuthCodeLabel: 'Skriv in returkod',
    inputAuthCodePlaceholder: 'Ex. 35424374',
    noresult: 'Hittade inte din returkod',
    checkifcorrectcode: 'Har du skrivit in rätt kod?',
    authcodeisnotshippingcode: 'Returkoden får du från avsändaren av paketet som du mottagit. Observera att sändingsnummeret på detta paketet inte är returkoden'
  },
  formLabels: {
    name: 'Namn',
    address: 'Adress',
    addressline2: 'Adresslinje 2',
    phoneNumber: 'Mobilnummer',
    email: 'E-post - om du vill få adresslappen/QR-kod via e-post',
    emailWithoutQrCode: 'E-post - om du vill få adresslappen via e-post',
    postalcode: 'Postnummer',
    city: 'Postort',
    country: 'Land',
    numberOfPackages: 'Antal kollin',
    reference: 'Referens'
  },
  confirmation: {
    title: 'Beställningsbekräftelse',
    from: 'Från',
    to: 'Till',
    printlabel: 'Skriva ut returetiketten',
    printlabelregular: 'Skriva ut returetikett på en vanlig skrivare (A4)',
    printlabelspecial: 'Skriva ut returetikett på en speciell etikettskrivare',
    trackingnumber: 'Spårningsnummer',
    orderpickup: {
      buttonLabel: 'Beställ Upphämtning ifrån brevlådan',
      contentheader: 'Upphämtning ifrån brevlådan',
      para1: 'Du kan enkelt beställa upphämtning av returen direkt ifrån din egen brevlåda. Om upphämtning är inkluderat för denna returen bekräftar du bara upphämtningsdatum. Alternativt kostar tilläggstjänsten NOK 15. För att vi ska veta vem du är och se till att vi har rätt adress måste du logga in innan du beställer.'
    },
    qrcodes: {
      title: 'QR-kod',
      intro: 'Du kan skriva ut returetiketten på alla Postens inlämningsställen genom att skanna QR-koden nedan.',
      otherwise: 'Du kan också själv skriva ut returetiketten i förväg, om du föredrar det.',
      here: 'Här är din QR-kod (en per kolli)',
      once: 'QR-koden kan bara användas en gång.'
    }
  },
  weightAndDimensions: {
    weight: 'Vikt (kg)',
    length: 'Längd (cm)',
    width: 'Bredd (cm)',
    height: 'Höjd (cm)'
  },
  errormsg: {
    invalidpostalcode: 'Ogiltigt postnummer',
    cannotbeempty: 'Måste fyllas i',
    maxpackages: 'Max 50',
    minonepackage: 'Min 1',
    errorhappened: 'Vi kan inte göra sökningar för tillfället',
    trylaterrefresh: 'Uppdatera sidan och försök igen',
    fieldlength: 'Måste vara mellan 1 och 35 tecken',
    exactlyfourdigits: 'Måste vara exakt 4 siffror',
    exactlyfivedigits: 'Måste vara exakt 5 siffror',
    emailformat: 'Ogiltig e-postadress',
    weight: 'Ogiltig vikt. Måste vara ',
    missingdimensionsfield: 'Vikt och mått krävs',
    dimensions1: 'Längsta sida max ',
    dimensions2: 'Längsta sida + omkrets max ',
    validationphoneNumberNO: 'Numret måste vara ett giltigt norskt telefonnummer (+47 12345678) ',
    validationphoneNumberSE: 'Numret måste vara ett giltigt svenskt telefonnummer (+46 700000000) ',
    validationphoneNumberDK: 'Numret måste vara ett giltigt danskt telefonnummer (+45 12345678) ',
    validationphoneNumberFI: 'Numret måste vara ett giltigt finskt telefonnummer (+358201234567) '


  },
  sender: 'Avsändare',
  packages: 'Paket',
  recipient: 'Mottagare',
  gotohomepagelink: 'Gå till förstasidan',
  btnlabels: {
    search: 'Sök',
    editreceiver: 'Redigera mottagare',
    generatelabel: 'Skapa adresslappen',
    creatinglabel: 'Skapar adresslappen...',
    usedefault: 'Använd standard',
    searching: 'Söker..',
    viewreturnlabel: 'Visa adresslappen'
  },
  countries: {
    norway: 'Norge',
    denmark: 'Danmark',
    sweden: 'Sverige',
    finland: 'Finland'
  },  
  saturdayDelivery: {
    eligible: 'Retursändning förväntas utleveras efter helgen, men kan påskyndas till kommande lördag.',
    eligibleUnconfirmed: 'Vi har tekniska problem, och kan inte verifiera att tilläggstjänst för utleverans lördag kommer att påskynda returen.',
    checkboxText: 'Beställ utleverans lördag'
  },
  newconfirmation: {
    back: 'Tillbaka',
    title: 'Din retursändning är beställd.',
    info: 'QR-koden kan användas för att skriva ut etiketten på ett av Postens inlämningsställen, eller så kan du skriva ut den själv.',
    qrCodes: {
      title: 'Din QR-kod',
      titleMultiple: 'Dina QR-koder (en per kolli)',
      package: 'kolli',
      of: 'av',
      sentTo: 'QR-koden har även skickats till',
      instructions: {
        title: 'Så här gör du:',
        stepOne: 'Besök ditt närmaste Postens inlämningsställe.',
        stepTwo: 'Skanna QR-koden. Etiketten skrivs automatiskt ut.',
        stepThree: 'Fäst etiketten i mittet av paketet med streckkoden tydligt synlig.',
        stepFour: 'Lämna in paketet.',
        pickupPoints: 'Här kan du hitta våra Post i Butikk och andra inlämningsställen.',
      },
    },
    printer: {
      title: 'Har du en skrivare tillgänglig?',
      info: 'Har du tillgång till en skrivare kan du även',
      printLabel: 'skriva ut adresslappen själv.',
    },
    pickup: {
      title: 'Skicka returen från din brevlåda?',
      info: 'När du har skrivit ut och förberett returetiketten kan du ',
      orderPickup: 'beställa hämtning från din brevlåda.',
    },
    tracking: {
      title: 'Hur man spårar returen efter inlämning',
      titleMultiple: 'Hur man spårar returen efter inlämning',
      info: 'När du har lämnat in din returförsändelse till någon av våra utlämningsplatser kan du spåra ditt paket med kollinummer ',
      infoMultiple: 'När du har lämnat in dina returer till en av våra utlämningsplatser kan du spåra dina paket med ett sändningsnummer ',
      at: ' på posten.no.'
    },
    newB2CReturnWith1073: {
      pickup: {
        title: 'Våra inlämningsställen',
        info: 'Hitta ditt ',
        infoLink: 'närmaste Postens inlämningsstalle.',
      },
      sendFromMailbox: {
        title: 'Skicka returen från din brevlåda eller paketskåp?',
        printLabel: 'Om du har tillgång till en skrivare kan du ',
        printLabelLink: 'skriva ut etiketten själv.',
        info: 'När du har returetiketten redo kan du gärna skicka paketet från både din egen postlåda eller använd en paketboks genom Posten-appen.',
        mailbox: 'Beställ upphämtning från brevlådan',
        parcelLocker: 'Öppna Posten-appen',
      },
    },
    nordicParcels: {
      info: 'Besök ett Bringombud och visa upp QR-koden i din mobil för att få din returlabel.',
      instructions: {
        title: 'Så här gör du:',
        stepOne: 'Besök ditt närmaste paketombud som använder Bring.',
        stepTwo: 'Visa upp QR-koden.',
        stepThree: 'Returlabeln fästs på paketet.'
      },
      findPostOffice: 'Hitta ett Bringombud',
      labelFree: {
        title: 'Behöver ombudet din kod?',
        info: 'Koden används istället för etikett på vissa ombud. Skriv koden nedan tydligt på paketet innan du lämnar det.'
      },
      printer: {
        title: 'Har du skrivare hemma?',
        info: 'Skriv ut returlabeln själv.',
      },
    },
  },
}

export default sv
