import React, { useContext } from 'react';
import { contentContext } from '../Context/ContentContext';
import * as envConfig from '../config/envConfig.js';
import { Mybicon } from '@norwaypost/mybring-iconsystem';
import * as Sentry from '@sentry/browser';
import NordicParcels from "../component/ConfirmationContent/NordicParcels";
import DomesticB2C from "../component/ConfirmationContent/DomesticB2C";
import { shouldRequestLabelFreePilot } from "../labelFreePilotService";
import LabelFreePilot from "../component/ConfirmationContent/LabelFreePilot";
export var QrCodeSection = function (_a) {
    var confirmation = _a.confirmation, translations = _a.translations, hasLabelFree = _a.hasLabelFree;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'hw-block hw-block--mt-large-2 hw-block--mb-large-2' },
            React.createElement("div", { className: "hw-message", style: { display: 'block' } },
                React.createElement("div", { className: "hw-message__body" },
                    React.createElement("div", null,
                        React.createElement(QrCodeInformation, { confirmation: confirmation, translations: translations, hasLabelFree: hasLabelFree }))))),
        React.createElement("div", { className: 'hw-block hw-block--mb' },
            React.createElement("div", { className: 'hw-wysiwyg' },
                React.createElement("h2", null, translations.instructions.title),
                React.createElement("ol", null,
                    React.createElement("li", null, translations.instructions.stepOne),
                    React.createElement("li", null, translations.instructions.stepTwo),
                    React.createElement("li", null, translations.instructions.stepThree),
                    React.createElement("li", null, translations.instructions.stepFour))))));
};
export var QrCodeInformation = function (_a) {
    var _b, _c;
    var confirmation = _a.confirmation, translations = _a.translations, hasLabelFree = _a.hasLabelFree;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'hw-block hw-block--px hw-text-medium hw-text-center' }, ((_b = confirmation.resp.packages) === null || _b === void 0 ? void 0 : _b.length) > 1 ? translations.titleMultiple : translations.title), (_c = confirmation.resp.packages) === null || _c === void 0 ? void 0 :
        _c.map(function (p, index) {
            var _a, _b;
            return (React.createElement("div", { key: index },
                ((_a = confirmation.resp.packages) === null || _a === void 0 ? void 0 : _a.length) > 1 &&
                    React.createElement("div", { className: "hw-block hw-block--px hw-text-small hw-text-center ".concat(index === 0 ? 'hw-block--mt-small' : '') },
                        translations.package,
                        " ",
                        index + 1,
                        " ",
                        translations.of,
                        " ", (_b = confirmation.resp.packages) === null || _b === void 0 ? void 0 :
                        _b.length),
                React.createElement("div", { className: 'hw-block hw-block--px hw-text-center hw-block--py' },
                    React.createElement("img", { alt: '', "data-testid": 'qrCodeImage', key: p.packageNumber, src: p.qrCodeLink }),
                    hasLabelFree &&
                        React.createElement("div", { className: 'hw-block--mt-small hw-block--px hw-text-medium hw-text-center', "data-testid": 'labelFreeCode' }, p.labelFreeCode))));
        }),
        confirmation.sender.email !== '' && React.createElement("div", { className: 'hw-block hw-block--px hw-text-center' },
            React.createElement("p", null,
                translations.sentTo,
                " ",
                confirmation.sender.email))));
};
var PickupSection = function (_a) {
    var translations = _a.translations, trackingNumber = _a.trackingNumber;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'hw-block hw-block--mt-large-2' },
            React.createElement("h2", { className: 'hw-block--mb-small-4 hw-text-medium hw-h3', "data-testid": 'pickupOrderHeader' }, translations.title),
            React.createElement("div", null,
                translations.info,
                React.createElement("a", { href: envConfig.url + trackingNumber, className: 'hw-link hw-link--inline', target: '_blank', rel: 'noopener noreferrer', "data-testid": 'viewOrderpickuplink' }, translations.orderPickup)))));
};
var confirmationContainer = function (_a) {
    var _b, _c, _d;
    var confirmation = _a.confirmation, postenOrBring = _a.postenOrBring;
    var ctx = useContext(contentContext);
    var isProduct9650 = function () {
        var _a;
        return ((_a = confirmation.recipient) === null || _a === void 0 ? void 0 : _a.returnProduct) === '9650';
    };
    var isPartOfLabelFreePilot = function () {
        return shouldRequestLabelFreePilot(confirmation.recipient.apiCustomerNumber, confirmation.recipient.returnProduct, confirmation.sender.countryCode, confirmation.sender.postalCode);
    };
    var isDomesticB2CReturn = function () {
        var _a;
        var applicableProducts = ['9300', '9350', '9650', 'PICKUP_PARCEL_RETURN', 'PICKUP_PARCEL_RETURN_BULK'];
        var isApplicableProduct = applicableProducts.includes(confirmation.recipient.returnProduct);
        var isDomestic = confirmation.sender.countryCode === 'NO' && confirmation.recipient.countryCode === 'NO';
        var has1073 = (_a = confirmation.recipient.valueAddedServices) === null || _a === void 0 ? void 0 : _a.includes('1073');
        return isApplicableProduct && isDomestic && has1073 && !isPartOfLabelFreePilot();
    };
    var isNordicParcelReturn = function () {
        var _a;
        var applicableProducts = ['PICKUP_PARCEL_RETURN', 'PICKUP_PARCEL_RETURN_BULK', 'BUSINESS_PARCEL_RETURN', 'BUSINESS_PARCEL_RETURN_BULK'];
        var isApplicableProduct = applicableProducts.includes(confirmation.recipient.returnProduct);
        var isNordicSender = confirmation.sender.countryCode === 'SE' || confirmation.sender.countryCode === 'DK';
        var has1288LabelFree = (_a = confirmation.recipient.valueAddedServices) === null || _a === void 0 ? void 0 : _a.includes('1288');
        return isApplicableProduct && isNordicSender && has1288LabelFree;
    };
    var image = postenOrBring === 'Posten' ? 'scanner-og-printer.png' : 'scanner-og-printer-bring.png';
    var language = window.location.href.includes('bring.no' || 'posten.no') ? '' : '?lang=en';
    var isMultiplePackages = ((_b = confirmation.resp.packages) === null || _b === void 0 ? void 0 : _b.length) > 1;
    var trackingTitle = isMultiplePackages ? ctx.t('newconfirmation.tracking.titleMultiple') : ctx.t('newconfirmation.tracking.title');
    var trackingInfo = isMultiplePackages ? ctx.t('newconfirmation.tracking.infoMultiple') : ctx.t('newconfirmation.tracking.info');
    var postenOrBringTracking = (confirmation.resp.links.tracking).includes('posten') ? 'posten' : 'bring';
    var consignmentNumber = confirmation.resp.consignmentNumber;
    var packageNumber = (_d = (_c = confirmation.resp.packages) === null || _c === void 0 ? void 0 : _c[0].packageNumber) !== null && _d !== void 0 ? _d : confirmation.resp.consignmentNumber;
    var trackingNumber = isMultiplePackages ? consignmentNumber : packageNumber;
    if (trackingNumber == null) {
        Sentry.captureMessage("package number was null. Confirmation obj: ".concat(JSON.stringify(confirmation), "."), 'error');
    }
    var trackingUrl = 'https://sporing.' + postenOrBringTracking + '.no/sporing/' + trackingNumber + language;
    return (React.createElement("div", { className: 'hw-block hw-block--mb-large-4' },
        React.createElement("div", { className: 'hw-container hw-container--mb hw-container--slim' },
            React.createElement("div", { className: 'hw-block hw-text-smaller' },
                React.createElement(Mybicon, { name: 'mybicon-arrow-left', width: 15, height: 15 }),
                React.createElement("span", { style: { marginRight: '10px' } }),
                React.createElement("a", { href: '', className: 'hw-link hw-link--inline', target: '_parent', rel: '' }, ctx.t('newconfirmation.back'))),
            React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'hw-block' },
                    React.createElement("h1", { className: 'hw-block--mt hw-block--mb', style: { outline: 'none' }, tabIndex: -1 }, ctx.t('newconfirmation.title')))),
            React.createElement(React.Fragment, null,
                isDomesticB2CReturn() &&
                    React.createElement(DomesticB2C, { confirmation: confirmation, translations: ctx.t('newconfirmation.newB2CReturnWith1073'), trackingNumber: trackingNumber, postenOrBring: postenOrBring }),
                isNordicParcelReturn() &&
                    React.createElement(NordicParcels, { confirmation: confirmation }),
                isPartOfLabelFreePilot() &&
                    React.createElement(LabelFreePilot, { confirmation: confirmation }),
                (!isDomesticB2CReturn() && !isNordicParcelReturn() && !isPartOfLabelFreePilot()) &&
                    React.createElement(React.Fragment, null,
                        React.createElement("p", null, ctx.t('newconfirmation.info')),
                        React.createElement("div", { className: 'hw-block hw-block--mt-large-2 hw-text-center' },
                            React.createElement("img", { src: 'images/' + image, alt: '', style: { maxWidth: '60%' } })),
                        React.createElement(QrCodeSection, { confirmation: confirmation, translations: ctx.t('newconfirmation.qrCodes'), hasLabelFree: false }),
                        React.createElement("a", { href: 'https://www.posten.no/kart', className: 'hw-link hw-link--inline', target: '_blank', rel: 'noopener noreferrer' }, ctx.t('newconfirmation.qrCodes.instructions.pickupPoints')),
                        React.createElement("div", { className: 'hw-block hw-block--mt-large-2' },
                            React.createElement("h2", { className: 'hw-block--mb-small-4 hw-text-medium hw-h3' }, ctx.t('newconfirmation.printer.title')),
                            React.createElement("p", null, ctx.t('newconfirmation.printer.info'))),
                        React.createElement("a", { href: confirmation.resp.links.labels, className: 'hw-link hw-link--inline', target: '_blank', rel: 'noopener noreferrer', "data-testid": 'viewLabelLink' }, ctx.t('newconfirmation.printer.printLabel')),
                        isProduct9650() &&
                            React.createElement(PickupSection, { translations: ctx.t('newconfirmation.pickup'), trackingNumber: trackingNumber }),
                        React.createElement("div", { className: 'hw-block hw-block--mt-large-2' },
                            React.createElement("h2", { className: 'hw-block--mb-small-4 hw-text-medium hw-h3' }, trackingTitle),
                            React.createElement("div", null,
                                trackingInfo,
                                React.createElement("a", { href: trackingUrl, className: 'hw-link hw-link--inline', target: '_blank', rel: 'noopener noreferrer', "data-testid": 'viewTrackingLink' }, trackingNumber),
                                ctx.t('newconfirmation.tracking.at'))))))));
};
export default confirmationContainer;
