import React, { Component } from 'react'
import Navbar from './component/Navbar.jsx'
import ContentContext from './Context/ContentContext.js'
import Hero from './component/HeroContent/Hero.jsx'
import ContentContainer from './Container/ContentContainer'
import translate from './i18n/i18n'
import ConfirmationContainer from './Container/ConfirmationContainer'

class App extends Component {
  state = {
    receiver: [],
    authCode: '',
    reference: '',
    fetchError: false,
    invalidReturnAgreement: false,
    authNotFound: false,
    confirmation: ''
  }

  componentDidMount () {
    const t = translate(window.location.href || '')
    const postenOrBring = (window.location.href || '').includes('posten') ? 'Posten' : 'Bring'
    document.title = t('pageTitle.title') + ' - ' + postenOrBring
  };

  render () {
    const setAuthCode = (auth) => { this.setState({ authCode: auth }) }
    const setReference = (ref) => { this.setState({ reference: ref }) }
    const setFetchError = (error) => { this.setState({ fetchError: error }) }
    const setInvalidReturnAgreement = (boolean) => { this.setState({ invalidReturnAgreement: boolean }) }
    const setConfirmation = (response) => { this.setState({ confirmation: response }) }
    const setAuthNotFound = (boolean) => { this.setState({ authNotFound: boolean }) }
    const setReceiverAddress = (addresses) => { this.setState({ receiver: addresses }) }

    return (
      <>
        <Navbar />
        <ContentContext.Provider value={{
          receiverAddresses: this.state.receiver,
          setReceiverAddress: setReceiverAddress,
          t: translate(window.location.href || '')
        }}
        >
          {this.state.confirmation === '' &&
            <Hero
              setAuthCode={setAuthCode}
              setFetchError={setFetchError}
              setAuthNotFound={setAuthNotFound}
              setConfirmation={setConfirmation}
              setReference={setReference}
              setInvalidReturnAgreement={setInvalidReturnAgreement}
            />
          }
          {this.state.confirmation !== ''
            ? <ConfirmationContainer
              confirmation={this.state.confirmation}
              postenOrBring={(window.location.href || '').includes('posten') ? 'Posten' : 'Bring'}
            />
            : <ContentContainer
              authCode={this.state.authCode}
              fetchError={this.state.fetchError}
              invalidReturnAgreement={this.state.invalidReturnAgreement}
              setFetchError={setFetchError}
              authNotFound={this.state.authNotFound}
              confirmation={this.state.confirmation}
              setConfirmation={setConfirmation}
              reference={this.state.reference}
            />
          }
        </ContentContext.Provider>
      </>
    )
  }
}
export default App
