const nb = {
  pageTitle: {
    title: 'Bestill ny retur'
  },
  authCodeSearch: {
    inputAuthCodeLabel: 'Skriv inn returkode',
    inputAuthCodePlaceholder: 'Eks. 35424374',
    noresult: 'Fant ikke din returkode',
    checkifcorrectcode: 'Har du skrevet inn riktig kode?',
    authcodeisnotshippingcode: 'Returkoden får du fra avsender av pakken du har mottatt. Merk at sendingsnummeret på denne pakken ikke er returkoden'
  },
  formLabels: {
    name: 'Navn',
    address: 'Adresse',
    addressline2: 'Adresselinje 2',
    phoneNumber: 'Mobilnummer',
    email: 'E-post - hvis du ønsker å motta adresselapp/QR-kode på e-post',
    emailWithoutQrCode: 'E-post - hvis du ønsker å motta adresselapp på e-post',
    postalcode: 'Postnummer',
    city: 'Sted',
    country: 'Land',
    numberOfPackages: 'Antall kolli',
    reference: 'Referanse',
    firstname: 'Fornavn',
    lastname: 'Etternavn',
    weight: 'Hvor mye veier pakken din?',
    returningwhat: 'Hva returnerer du?'
  },
  confirmation: {
    title: 'Bestillingsbekreftelse',
    from: 'Fra',
    to: 'Til',
    printlabel: 'Skriv ut returetiketten',
    printlabelregular: 'Skriv ut returetikett på vanlig skriver (A4)',
    printlabelspecial: 'Skriv ut returetikett på spesialskriver for etiketter',
    trackingnumber: 'Sporingsnummer',
    orderpickup: {
      buttonLabel: 'Bestill Opphenting fra postkassen',
      contentheader: 'Opphenting fra postkassen',
      para1: 'Du kan enkelt bestille opphenting av returen rett fra din egen postkasse. Dersom opphenting er inkludert for denne returen, bekrefter du kun dato for opphenting. Alternativt koster tilleggstjenesten NOK 15. For at vi skal vite hvem du er og sikre at vi har riktig adresse, ber vi deg logge inn før du skal bestille.'
    },
    qrcodes: {
      title: 'QR-kode',
      intro: 'Du kan skrive ut returetiketten på alle Postens innleveringssteder ved å scanne QR-koden under.',
      otherwise: 'Du kan også selv skrive ut returetiketten på forhånd, dersom du ønsker det.',
      here: 'Her er din QR-kode (én per kolli)',
      once: 'QR-koden kan kun brukes én gang.'
    }
  },
  weightAndDimensions: {
    weight: 'Vekt (kg)',
    length: 'Lengde (cm)',
    width: 'Bredde (cm)',
    height: 'Høyde (cm)'
  },
  errormsg: {
    invalidpostalcode: 'Ugyldig postnummer',
    cannotbeempty: 'Må fylles ut',
    maxpackages: 'Maks 50',
    minonepackage: 'Min 1',
    errorhappened: 'Vi kan ikke gjøre søk akkurat nå',
    trylaterrefresh: 'Oppdater siden og prøv igjen',
    fieldlength: 'Må være mellom 1 og 35 tegn',
    exactlyfourdigits: 'Må være nøyaktig 4 sifre',
    exactlyfivedigits: 'Må være nøyaktig 5 sifre',
    emailformat: 'Ugyldig epostadresse',
    weight: 'Ugyldig vekt. Må være ',
    missingdimensionsfield: 'Vekt og dimensjoner må fylles ut',
    dimensions1: 'Lengste side maks ',
    dimensions2: 'Lengste side + omkrets maks ',
    validationphoneNumberNO: 'Nummeret må være et gyldig norsk telefonnummer (+47 12345678) ',
    validationphoneNumberFI: 'Nummeret må være et gyldig finsk telefonnummer (+358201234567)',
    validationphoneNumberDK: 'Nummeret må være et gyldig dansk telefonnummer (+45 12345678)',
    validationphoneNumberSE: 'Nummeret må være et gyldig svensk telefonnummer (+46 700000000)'
  },
  sender: 'Avsender',
  packages: 'Pakker',
  recipient: 'Mottaker',
  gotohomepagelink: 'Gå til forsiden',
  btnlabels: {
    search: 'Søk',
    editreceiver: 'Endre mottaker',
    generatelabel: 'Lag adresselapp',
    creatinglabel: 'Lager adresselapp...',
    usedefault: 'Bruk standard',
    searching: 'Søker..',
    viewreturnlabel: 'Vis adresselapp'
  },
  countries: {
    norway: 'Norge',
    denmark: 'Danmark',
    sweden: 'Sverige',
    finland: 'Finland'
  },
  smallpacketmaxsize: 'Maksimumsmål per pakke: Lengde 60 cm og lengde + bredde + høyde ikke over 90 cm',
  saturdayDelivery: {
    eligible: 'Returen har forventet utlevering over helgen, men kan fremskyndes til førstkommende lørdag.',
    eligibleUnconfirmed: 'Vi har tekniske problemer, og kan ikke verifisere at tilleggstjenesten lørdagsutlevering vil fremskynde returen.',
    checkboxText: 'Bestill utlevering lørdag'
  },
  newconfirmation: {
    back: 'Tilbake',
    title: 'Din retursending er bestilt.',
    info: 'QR-koden kan brukes til å skrive ut adresselappen hos Posten eller du kan skrive den ut selv.',
    qrCodes: {
      title: 'Din QR-kode',
      titleMultiple: 'Dine QR-koder (en per kolli)',
      package: 'kolli',
      of: 'av',
      sentTo: 'QR-koden er også sendt til',
      instructions: {
        title: 'Slik gjør du:',
        stepOne: 'Besøk din nærmeste Post i Butikk eller Postkontor.',
        stepTwo: 'Skann QR-koden. Adresselappen skrives ut automatisk.',
        stepThree: 'Fest adresselappen midt på pakken med strekkoden godt synlig.',
        stepFour: 'Lever inn pakken.',
        pickupPoints: 'Her kan du finne Post i Butikk og innleveringsstedene våre.',
      },
    },
    printer: {
      title: 'Har du tilgang på printer?',
      info: 'Dersom du har mulighet med printer, kan du også',
      printLabel: 'skrive ut adresselappen selv.',
    },
    pickup: {
      title: 'Sende returen fra din postkasse?',
      info: 'Når du har skrevet ut og gjort returetiketten klar, kan du ',
      orderPickup: 'bestille opphenting fra din postkasse.'
    },
    tracking: {
      title: 'Slik sporer du returen etter innlevering',
      titleMultiple: 'Slik sporer du returen etter innlevering',
      info: 'Når du har levert inn din retursending til et av våre innleveringssteder, kan du spore pakken din med kollinummer ',
      infoMultiple: 'Når du har levert inn dine retursendinger til et av våre innleveringssteder, kan du spore pakkene dine med sendingsnummer ',
      at: ' på posten.no.'
    },
    newB2CReturnWith1073: {
      pickup: {
        title: 'Våre innleveringssteder',
        info: 'Finn ditt ',
        infoLink: 'nærmeste postkontor eller Post i Butikk.',
      },
      sendFromMailbox: {
        title: 'Sende returen fra Postkassen eller Pakkeboks?',
        printLabel: 'Har du tilgang på printer kan du ',
        printLabelLink: 'skrive ut adresselappen selv.',
        info: 'Når du har returadresselappen klar, kan du gjerne sende pakken fra din egen postkasse eller bruk pakkeboks gjennom Posten appen.',
        mailbox: 'Bestill henting fra postkasse',
        parcelLocker: 'Åpne Posten appen',
      },
    },
    nordicParcels: {
      info: 'Besøk et Bring ombud og vis QR-koden for å få din adresselapp.',
      instructions: {
        title: 'Slik gjør du:',
        stepOne: 'Finn ditt nærmeste Bring ombud.',
        stepTwo: 'Vis QR-koden.',
        stepThree: 'Den ansatte skriver ut adresselappen din.'
      },
      findPostOffice: 'Finn et Bring ombud',
      labelFree: {
        title: 'Ber ombudet deg bruke sendekode?',
        info: 'Enkelte ombud kan ikke skrive ut adresselapp. Skriv koden nedenfor tydelig og midt på pakken.'
      },
      printer: {
        title: 'Har du printer hjemme?',
        info: 'Skriv ut adresselappen selv.',
      },
    },
  },
}

export default nb
